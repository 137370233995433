import {
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FaGlobeAmericas } from "react-icons/fa";

const SwitchLangBtn = ({ ...props }) => {
  const router = useRouter();
  const { locale } = router;
  return (
    <Menu zIndex="999">
      <MenuButton
        bg="white"
        as={IconButton}
        aria-label="Options"
        icon={<FaGlobeAmericas />}
        {...props}
      />
      <MenuList {...props}>
        <MenuOptionGroup defaultValue={locale} type="radio">
          <MenuItemOption
            value="zh-TW"
            onClick={() => {
              router.push(router.pathname, router.asPath, {
                locale: "zh-TW",
              });
            }}
          >
            中文
          </MenuItemOption>
          <MenuItemOption
            value="en"
            onClick={() => {
              router.push(router.pathname, router.asPath, {
                locale: "en",
              });
            }}
          >
            English
          </MenuItemOption>
          <MenuItemOption
            value="jp"
            onClick={() => {
              router.push(router.pathname, router.asPath, {
                locale: "jp",
              });
            }}
          >
            日本語
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
export default SwitchLangBtn;
