import { useEffect } from "react";
import { useRouter } from "next/router";
import * as ga from "../lib/analytics";
import * as atatus from "atatus-spa";

export default function TrackingHandler() {
  const router = useRouter();

  // Atatus
  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.ATATUS_URL;
    script.async = true; // 確保腳本異步加載
    script.onload = () => {
      if (window.atatus) {
        atatus.config(process.env.ATATUS_ID).install();
      }
    };
    // 將腳本元素添加到文檔頭部
    document.head.appendChild(script);
    // 在組件卸載時移除腳本
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Google Analytics
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  return null;
}
