import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import MyImage from "components/MyImage";
import { useSession } from "hooks/useSession";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import appStore from "store";
import api from "store/api";

const InviteModal = () => {
  const router = useRouter();
  const session = useSession();
  const { token } = session;
  const { t } = useTranslation("common");
  const {
    isInvitedModalVisible: isInvitedOpen,
    isGetInviteCouponModalVisible: isInviteMemberOpen,
  } = appStore;

  const [mgmData, setMgmData] = useState(null);

  useEffect(() => {
    if (!token) return;
    api.getMyMgmData().then((resp) => {
      setMgmData(resp.data);
    });
  }, [token]);

  const readReferralNotify = () => {
    if (isInviteMemberOpen) {
      const data = {
        referral_id: mgmData.new_referral_id,
      };
      api.readReferralNotify(data).then((res) => {
        console.log("readReferralNotify", res);
      });
    }

    appStore.isInvitedModalVisible = false;
    appStore.isGetInviteCouponModalVisible = false;
  };

  const onClose = () => {
    readReferralNotify();
  };

  const goRent = () => {
    let code = "";
    api.getMyMgmData().then((resp) => {
      setMgmData(resp.data);
      code = isInvitedOpen
        ? resp.data.referred_coupon.code
        : resp.data.referrer_coupon.code;
      readReferralNotify();
      router.push({
        pathname: "/rent",
        query: {
          ...router.query,
          useCoupon: code,
        },
      });
    });
  };

  const goCoupon = () => {
    readReferralNotify();
    router.push({ pathname: "/account/coupon", query: router.query });
  };

  return (
    <Box>
      <Modal
        isOpen={isInvitedOpen || isInviteMemberOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "calc(100vw - 32px)", md: "358px" }}
          h="auto"
          borderRadius="16px"
        >
          <ModalBody
            textAlign="start"
            color="#DEE3F0"
            p="0px"
            mx="16px"
            mb="16px"
          >
            <MyImage
              ml="40px"
              w="272px"
              src="/images/invite/airplane.png"
              alt="airplane"
            />
            <Text
              fontSize="16px"
              lineHeight="24px"
              fontWeight="700"
              color="#333333"
            >
              {isInvitedOpen
                ? t("Invite_get_invited_coupon")
                : t("Invite_get_invite_coupon")}
            </Text>
            <Text fontSize="14px" lineHeight="20px" color="#748598">
              {isInvitedOpen
                ? t("Invite_get_invited_coupon_info")
                : t("Invite_get_invite_coupon_info")}
            </Text>

            <Flex mt="24px">
              <Flex
                flex="1"
                mr="16px"
                h="56px"
                align="center"
                justify="center"
                cursor="pointer"
                onClick={goCoupon}
              >
                <Text
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="700"
                  color="#28292B"
                >
                  {t("Invite_go_see_coupon")}
                </Text>
              </Flex>
              <Flex
                flex="1"
                h="56px"
                align="center"
                justify="center"
                bg="#333333"
                borderRadius="8px"
                cursor="pointer"
                onClick={goRent}
              >
                <Text
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="700"
                  color="white"
                >
                  {t("Invite_go_use_coupon")}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InviteModal;
