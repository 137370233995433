export const DEFAULT_DELIVERY_ZONE_LIST = [
  { id: 4, level_1: "台中市" },
  { id: 56, level_1: "台北市", dev_id: 1 },
  { id: 89, level_1: "台南市" },
  { id: 174, level_1: "宜蘭縣" },
  { id: 7, level_1: "苗栗縣" },
  { id: 2, level_1: "桃園市", dev_id: 2 },
  { id: 90, level_1: "高雄市" },
  { id: 468, level_1: "基隆市" },
  { id: 87, level_1: "雲林縣" },
  { id: 60, level_1: "新北市", dev_id: 7 },
  { id: 470, level_1: "新竹市" },
  { id: 6, level_1: "新竹縣" },
  { id: 88, level_1: "嘉義縣" },
  { id: 86, level_1: "彰化縣" },
];
